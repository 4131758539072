<template>
    <div :style="{ width: width, height: height }">
        <Doughnut
            :chart-options="chartOptions"
            :chart-data="chartDataList"
            :plugins="plugin"
            :styles="styles"
            chart-id="doughnut-chart"
            dataset-id-key="label-id"
            css-classes="doughnut"
        />
    </div>
</template>

<script>
import _ from 'lodash'
import { Doughnut } from 'vue-chartjs'
import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    ArcElement,
    CategoryScale
} from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale)

export default {
    name: 'DoughnutChart',
    components: { Doughnut },
    props: {
        datasets: {
            type: Array,
            default: function () {
                return [{ label: '', value: 0, color: '' }]
            }
        },
        datasetIdKey: {
            type: String,
            default: 'label'
        },
        width: {
            type: String,
            default: '180px'
        },
        height: {
            type: String,
            default: '180px'
        },
        cssClasses: {
            type: String,
            default: 'doughnut'
        },
        totalUnit: {
            type: String,
            default: '單位'
        },
        totalValueSlot: {
            type: [String, Number],
            default: null
        },
        totalStyle: {
            type: Object,
            default: function () {
                return {
                    total: { color: '#333333', fontSize: '20px' },
                    unit: { color: '#8A8E99', fontSize: '16px' }
                }
            }
        }
    },
    methods: {
        drawTotals: function (chart) {
            const width = chart.width,
                height = chart.height,
                ctx = chart.ctx

            ctx.restore()

            //增加總和白色背景
            ctx.beginPath()
            ctx.arc(
                Math.round(width / 2),
                height / 2,
                (width / 2) * 0.7,
                -1,
                Math.PI * 2,
                false
            )
            ctx.fillStyle = '#fff'
            ctx.fill()

            //總計文字樣式設定
            ctx.textAlign = 'start'
            //total
            ctx.font = `900 ${this.totalStyle.total.fontSize} Roboto, sans-serif`
            ctx.fillStyle = this.totalStyle.total.color
            const totalsSlot =
                typeof this.totalValueSlot === 'number'
                    ? this.$numberWithComma(this.totalValueSlot)
                    : this.totalValueSlot
            const total = this.totalValueSlot
                    ? totalsSlot
                    : this.$numberWithComma(this.totalValue ?? 0),
                totalX = Math.round((width - ctx.measureText(total).width) / 2),
                totalY = height / 2
            ctx.fillText(total, totalX, totalY)

            //單位
            ctx.font = `900 ${this.totalStyle.unit.fontSize} Roboto, sans-serif`
            ctx.fillStyle = this.totalStyle.unit.color
            const unit = this.totalUnit ?? '',
                unitX = Math.round((width - ctx.measureText(unit).width) / 2),
                unitY = height / 2 + 25
            ctx.fillText(unit, unitX, unitY)

            ctx.save()
        }
    },
    computed: {
        chartDataList: function () {
            const chartData = _.cloneDeep(this.datasets)
            return {
                labels: chartData.map((x) => x.label),
                datasets: [
                    {
                        data: chartData.map((x) => x.value),
                        backgroundColor: chartData.map((x) => x.color)
                    }
                ]
            }
        },
        totalValue: function () {
            const numList = _.cloneDeep(this.datasets).map((x) => x.value)
            return numList.reduce((a, b) => {
                return a + b
            })
        },
        chartOptions: function () {
            return {
                responsive: true,
                cutout: '85%',
                radius: '94%',
                borderRadius: 15,
                borderWidth: 0,
                spacing: 4,
                borderColor: '#F3F5FA',
                plugins: {
                    legend: {
                        display: false //顯示套件label
                    }
                }
            }
        },
        styles: function () {
            return {
                backgroundColor: '#F3F5FA',
                borderRadius: '50%'
            }
        },
        plugin: function () {
            return [
                {
                    id: 'doughnut-chart',
                    beforeDraw: (chart) => {
                        this.drawTotals(chart)
                    }
                }
            ]
        }
    },
    data() {
        return {}
    },
    mounted() {}
}
</script>
<style lang="scss" scoped>
.doughnut-chart {
    width: 100%;
}
</style>
