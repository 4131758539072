import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import common from './assets/javascripts/common'
import VueGtag from 'vue-gtag-next'
const app = createApp(App)
    .use(store)
    .use(router)
    .use(common)
    .use(VueGtag, {
        property: [
            {
                id: process.env.VUE_APP_GA4_CODE
            }
        ]
        // isEnabled: process.env.VUE_APP_ENV !== 'production'
    })

app.config.unwrapInjectedRef = true
app.mount('#app')
