<template>
    <router-view />
</template>

<script>
import smoothscroll from 'smoothscroll-polyfill'
export default {
    methods: {
        scrollToTop: function () {
            this.$nextTick(() => {
                smoothscroll.polyfill()
                const html = document.querySelector('html')

                html.scrollTo({
                    top: 0
                })
            })
        }
    },
    watch: {
        '$route.path': {
            handler() {
                let routeName = this.$route.name || ''
                if (routeName === 'CommissionAdmin') {
                    routeName =
                        this.$route.name +
                        this.$firstCapitalized(this.$route.params.mType)
                }
                this.$store.commit('set', {
                    permissionRouteName: routeName
                })
                this.scrollToTop()
            },
            deep: true,
            immediate: true
        }
    }
}
</script>

<style lang="scss">
@import '@/assets/styles/common';
@import '@/assets/styles/icon';
</style>
