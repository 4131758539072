import { createStore } from 'vuex'
import VuexPersistence from 'vuex-persist'
import localForage from 'localforage'
import { initialVuexStore } from '../assets/javascripts/setting'

localForage.config({
    name: 'bms',
    storeName: 'codes'
})

const vuexLocalStorage = new VuexPersistence({
    storage: window.localStorage,
    reducer: (state) => {
        const data = { ...state }
        delete data.accounts
        return data
    }
})
const vuexIndexedDb = new VuexPersistence({
    storage: {
        getItem: async (key) => {
            const serializedData = await localForage.getItem(key)
            return JSON.parse(JSON.stringify(serializedData))
        },
        setItem: async (key, value) => {
            const serializedData = JSON.stringify(value)
            await localForage.setItem(key, serializedData)
        }
    },
    reducer: (state) => {
        return {
            accounts: state.accounts
        }
    },
    asyncStorage: true
})

initialVuexStore.plugins = [vuexIndexedDb.plugin, vuexLocalStorage.plugin]

export default createStore(initialVuexStore)
