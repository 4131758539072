import qs from 'qs'
import _ from 'lodash'
import { ssoConfig, initialVuexStore } from '@/assets/javascripts/setting.js'
//const api = () => import('@/assets/javascripts/api.js')//賓果卡點擊動作紀錄

// export it as a plugin
const mixin = {
    methods: {
        $login: function () {
            window.location = ssoConfig.sso
            this.$setGaEvent(`login`, 'click-Link')
        },
        $logout: async function () {
            this.$setGaEvent(`logout`, 'click-Link')
            const payload = {
                token: this.$store.getters.refreshToken,
                token_type_hint: 'refresh_token',
                client_id: 'bms',
                redirect_uri: `${window.location.origin}${
                    process.env.VUE_APP_ENV === 'feature' ? '/feature' : ''
                }/login`
            }

            this.$store.commit('set', initialVuexStore.state)

            window.location = `${ssoConfig.sso_domain}logout?${qs.stringify(
                payload
            )}`
        },
        $showLoading: function () {
            this.$store.commit('set', {
                isLoading: true
            })
        },
        $hideLoading: function () {
            this.$store.commit('set', {
                isLoading: false
            })
        },
        $getResponse: async function (
            payload /* 使用者點選的欄位資料*/,
            permission /* 存放頁面權限資料 (若尚未設定則輸入:'skip')*/,
            apiHandler /* 頁面呼叫的API*/
        ) {
            try {
                if (
                    !this.$hasPermission(permission.apiAlias) &&
                    permission !== 'skip'
                ) {
                    throw new Error(` no ${permission.apiAlias} Permission `)
                }
                const response = await apiHandler(payload)
                return response
            } catch (error) {
                if (
                    error.message === ` no ${permission.apiAlias} Permission `
                ) {
                    this.$showErrorMessage(
                        `此帳號尚無查看${permission.apiName}的權限`,
                        '請確認您的帳號權限，如有疑問請洽飛鴿'
                    )
                } else
                    this.$showErrorMessage(
                        '查詢時出現錯誤，請稍後再試',
                        '若持續出現此問題，請聯繫資訊客服'
                    )
                return {}
            }
        },
        $showErrorMessage: function (errorMessage, errorDetail, errorHandler) {
            this.$store.commit('set', {
                showErrorModal: true,
                errorMessage: errorMessage,
                errorDetail: errorDetail,
                errorHandler: errorHandler
            })
            this.$setGaEvent('showErrorMessage', 'show-Modal')
        },
        $isLogin: function () {
            return this.$store.getters.isLogin
        },
        $isAdmin: function () {
            return this.$store.getters.isAdmin
        },
        $isTitleCAup: function () {
            return this.$store.getters.isTitleCAup
        },
        $numberWithComma: function (nStr) {
            nStr += ''
            nStr = nStr.replace(/,/g, '')
            var parts = nStr.toString().split('.')
            parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            return parts.join('.')
        },
        $numberRemoveComma: function (nStr) {
            nStr += ''
            return Number(nStr.replace(/,/g, ''))
        },
        $getPureNumber: function (numberString) {
            if (typeof numberString === 'number') return numberString
            if (!numberString) return numberString
            const replacement = numberString.replace('元', '').replace('%', '')
            return Number(this.$numberRemoveComma(replacement))
        },
        $getUrlParam: function () {
            if (!window.location.search) {
                return null
            }
            var params = window.location.search.replace('?', '').split('&')
            var obj = {}
            for (const param of params) {
                var key = param.split('=')[0]
                var value = decodeURI(param.split('=')[1])
                obj[key] = value
            }
            return obj
        },
        $addAllClass: function (elements, className) {
            if (Array.isArray(elements)) {
                for (const element of elements) {
                    if (Array.isArray(className)) {
                        for (const cls of className) {
                            element.classList.add(cls)
                        }
                    } else {
                        element.classList.add(className)
                    }
                }
            } else if (
                Object.prototype.hasOwnProperty.call(NodeList, elements)
            ) {
                for (const element of elements) {
                    if (Array.isArray(className)) {
                        for (const cls of className) {
                            element.classList.add(cls)
                        }
                    } else {
                        element.classList.add(className)
                    }
                }
            } else {
                if (Array.isArray(className)) {
                    for (const cls of className) {
                        elements.classList.add(cls)
                    }
                } else {
                    elements.classList.add(className)
                }
            }
        },
        $removeAllClass: function (elements, className) {
            if (Array.isArray(elements)) {
                for (const element of elements) {
                    if (Array.isArray(className)) {
                        for (const cls of className) {
                            element.classList.remove(cls)
                        }
                    } else {
                        element.classList.remove(className)
                    }
                }
            } else if (
                Object.prototype.hasOwnProperty.call(NodeList, elements)
            ) {
                for (const element of elements) {
                    if (Array.isArray(className)) {
                        for (const cls of className) {
                            element.classList.remove(cls)
                        }
                    } else {
                        element.classList.remove(className)
                    }
                }
            } else {
                if (Array.isArray(className)) {
                    for (const cls of className) {
                        elements.classList.remove(cls)
                    }
                } else {
                    elements.classList.remove(className)
                }
            }
        },
        $isMobile: function (type) {
            if (type && type.toLowerCase() === 'agent') {
                // use user agent
                let check = false
                ;(function (a) {
                    if (
                        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
                            a
                        ) ||
                        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
                            a.substr(0, 4)
                        )
                    )
                        check = true
                    /*
                     * 判斷iPad iOS13以前iPad可以用UserAgent判斷， 13以後的UserAgent會與Mac一樣
                     * 所以13以後使用platform及maxTouchPoints做判断 (較不嚴謹，若有出可觸控mac則需回來改)
                     */
                    if (
                        navigator.platform === 'MacIntel' &&
                        navigator.maxTouchPoints > 1
                    )
                        check = true
                })(navigator.userAgent || navigator.vendor || window.opera)
                return check
            } else {
                // use window width
                let width =
                    window.innerWidth ||
                    document.documentElement.clientWidth ||
                    document.body.clientWidth
                return width <= 576
            }
        },
        $toLocalString: function (date) {
            let getDate = new Date(date)
            return getDate.toLocaleString([], {
                timeZone: 'Asia/Taipei',
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                hour12: false
            })
        },
        $getDate: function (day, month, year, date) {
            const d = day || 0
            const m = month || 0
            const y = year || 0
            let setDay = date || this.$formatDate(new Date())
            let today = setDay instanceof Date ? setDay : new Date(setDay)
            let origin = setDay instanceof Date ? setDay : new Date(setDay)

            today.setDate(today.getDate() + d)
            today.setMonth(today.getMonth() + m)
            today.setYear(today.getFullYear() + y)

            //如果setDay是大月最後一天，減月份:自動變為上個月份最後一天，增月份:當日日期+30天
            if (today.getDate() !== origin.getDate() && d === 0) {
                //當月第一天
                today.setDate(1)
                //下個月第一天
                today.setMonth(origin.getMonth() + m + 1)
                //-1天回到上個月
                today.setDate(today.getDate() - 1)
            }

            let theDay = new Date(today)
            return theDay
        },
        $formatDate: function (date, formatter) {
            const f = formatter || '-'
            return date.toISOString().slice(0, 10).replace(/-/g, f)
        },
        $getFirstDate: function (date) {
            let setDay = date || new Date()
            let today = setDay instanceof Date ? setDay : new Date(setDay)
            today.setDate(1)

            let theDay = new Date(today)
            return theDay
        },
        $getLastDate: function (date) {
            let setDay = date || new Date()
            let today = setDay instanceof Date ? setDay : new Date(setDay)
            today.setDate(1)

            let theDay = new Date(today)
            today = this.$getDate(0, 1, 0, theDay)
            return this.$getDate(-1, 0, 0, theDay)
        },
        $roundX: function (number, decimal) {
            const precision = decimal || 2
            if (decimal === 0) {
                return Math.round(number)
            }
            return (
                Math.round(
                    Math.round(number * Math.pow(10, precision + 1)) / 10
                ) / Math.pow(10, precision) || 0
            )
        },
        $addPointNumber: function (number, decimal) {
            if (typeof number !== 'number' || number == 0) return number
            else {
                return number.toFixed(decimal)
            }
        },
        $toPercentage: function (number, decimal) {
            const precision = decimal || 2
            return this.$roundX(
                this.$roundX(number, precision + 2) * 100,
                precision
            ).toFixed(decimal)
        },
        $getDocumentWidth: function () {
            return (
                window.innerWidth ||
                document.documentElement.clientWidth ||
                document.body.clientWidth
            )
        },
        $getHeight: function () {
            return (
                window.innerHeight ||
                document.documentElement.clientHeight ||
                document.body.clientHeight
            )
        },
        $isMobileHorizontal: function () {
            let width =
                window.innerWidth ||
                document.documentElement.clientWidth ||
                document.body.clientWidth
            let height =
                window.innerHeight ||
                document.documentElement.clientHeight ||
                document.body.clientHeight

            return width > height && height <= 420
        },
        $checkHistoryPayload: function (isMobile, pageName) {
            const noLastPage = this.$store.state.noLastPage
            const mobileReloadPayload = this.$store.state.storePayload[pageName]
            const payloadKeys = Object.keys(mobileReloadPayload)

            const isMobileReload =
                isMobile &&
                Object.values(mobileReloadPayload[payloadKeys[0]]).length

            if (isMobileReload && noLastPage) {
                return mobileReloadPayload
            } else {
                this.isMobileReload = false
                this.$store.commit('setHistoryPayload', {
                    routerName: pageName
                })

                return false
            }
        },
        $transformInputType: function (_response) {
            const response = _.cloneDeep(_response)
            const toStringList = [
                'manCode',
                'supplier',
                'titleTypes',
                'accounts',
                'contests'
            ]
            const toStringSubList = ['key', 'titleTypeId', 'contestId']
            toStringList.forEach((key) => {
                if (response[key]) {
                    response[key] = response[key].map((item) => {
                        toStringSubList.forEach((subKey) => {
                            if (item[subKey])
                                item[subKey] = item[subKey].toString()
                        })
                        return item
                    })
                }
            })
            return response
        },
        $setGaEvent: function (label, action, setting, value) {
            /*事件category的路由名稱後面是否要加params*/
            let params = ''
            if (setting?.params) params = '/' + setting.params
            else if (this.$route.params?.mType)
                params = '/' + this.$route.params.mType
            else if (this.$route.params?.mode)
                params = '/' + this.$route.params.mode

            const route = this.$route.name
            const category = route + params

            /*事件label的觸發點是否在modal跳窗中*/
            let modal = ''
            if (setting?.isModal || this.$store.state.isShowModalForGa)
                modal = '(modal)'

            const eventLabel = label + modal

            if (value)
                this.$gtag.event(action, {
                    event_category: category,
                    event_label: eventLabel,
                    value: parseInt(value)
                })
            else
                this.$gtag.event(action, {
                    event_category: category,
                    event_label: eventLabel
                })
        },
        $setRowSortLabel: function (row, columns) {
            let setRow = _.cloneDeep(row)
            Object.keys(columns).forEach((col) => {
                if (typeof setRow[col] === 'object') {
                    setRow[col + 'Label'] = setRow[col]?.label
                }
            })
            return setRow
        },
        $downloadZipHelper: function (res, key) {
            const zips = res.data[key]
            if (!zips) return
            zips.forEach((zip) => {
                const link = document.createElement('a')
                link.href = zip
                link.click()
            })
        },
        $getDropdownLabel: function (key, value) {
            /*
             ** 若呼叫的component內無設定filterOptions將無法使用此mixin function
             ** 把下拉選單的值 換成人看得懂的中文對照，皆使用弱型別比較
             ** titleType、manCode、bgCode、role不從FilterOption)比對
             */
            let label
            if (key === 'titleType') {
                label =
                    this.$store.state?.accounts?.find(
                        (account) => account.titleTypeId == value
                    )?.titleType ?? '全部'
            } else if (key === 'manCode') {
                label = this.$store.state?.accounts?.find(
                    (account) => account.key == value
                )?.value
                //因應客戶服務頁面 payload架構調整新增
            } else if (key === 'manCodes') {
                label = this.$store.state?.accounts?.find(
                    (account) => account.key == value[0]
                )?.value
            } else if (key === 'bgCode') {
                this.$store.state?.accounts?.forEach((account) => {
                    const bgName = account.leaderBgCodes.find(
                        (bg) => bg.key == value
                    )?.value
                    if (bgName) label = bgName
                })
            } else if (key === 'role') {
                if (value === 'ceo') label = '執行長'
                if (value === 'supervisor') label = '業務主管'
            } else {
                const options = this.filterOptions[key]
                if (Array.isArray(value)) {
                    label = value
                        .map((v) => options.find((o) => o.value === v)?.label)
                        .join(', ')
                } else {
                    label = options?.find((o) => o.value === value)?.label
                }
            }

            return label || value
        },
        $getFilterLog: function (payload) {
            /*
             ** 若呼叫的component內無設定filterTitles將無法使用此mixin function
             */
            return Object.keys(payload).reduce((memo, key) => {
                const value = payload[key]
                memo[key] = {
                    seq: this.filterTitles._seq?.indexOf(key) + 1,
                    title: this.filterTitles[key],
                    value: value,
                    label: this.$getDropdownLabel(key, value)
                }
                return memo
            }, {})
        },
        $getClassNameFromCamel: function (key) {
            return key.replace(/\B([A-Z])/g, '-$1').toLowerCase()
        },
        $getColumnSetting: function (columnKey) {
            // 這裡的this.columns必須是轉過Array的不是Object
            if (!Array.isArray(this.columns)) return {}
            let columnSetting
            this.columns.forEach((column) => {
                if (column.key === columnKey) columnSetting = column
                if (column.alias) {
                    Object.keys(column.alias).forEach((aliasKey) => {
                        if (aliasKey === columnKey)
                            columnSetting = column.alias[aliasKey]
                    })
                }
            })
            return columnSetting
        },
        $getColumnKey: function (columnKey) {
            const column = this.$getColumnSetting(columnKey)
            const alias = column?.alias
            return alias ? Object.keys(alias)[0] : columnKey
        },
        $getColumnLabel: function (columnKey) {
            const column = this.$getColumnSetting(columnKey)
            const alias = column?.alias
            return alias ? Object.values(alias)[0]?.label : column?.label
        },
        $firstCapitalized: function (str) {
            let string = str
            return string.charAt(0).toUpperCase() + string.slice(1)
        },
        $setRequiredText: function (el, val, text) {
            if (!val) {
                el.setCustomValidity(text)
            } else {
                el.setCustomValidity('')
            }
        },
        $reloadTips: function () {
            this.$store.commit('set', {
                isReloadTips: true
            })
        },
        $stringFullToHalf: function (str) {
            /*
             ** 英數特殊符號全形轉半形
             */
            if (str.length <= 0) return ''
            let result = ''
            for (let i = 0; i < str.length; i++) {
                const regexp = /[\u4E00-\u9fa5\u3105-\u3129\u02CA\u02C7\u02CB\u02D9]/
                if (
                    str.charCodeAt(i) > 65280 &&
                    str.charCodeAt(i) < 65375 &&
                    !regexp.test(str.charAt(i))
                ) {
                    result += String.fromCharCode(str.charCodeAt(i) - 65248)
                } else {
                    result += str.charAt(i)
                }
            }
            return result
        },
        $renderSize: function (value) {
            if (!value) {
                return '0 Bytes'
            }
            const units = [
                'Bytes',
                'KB',
                'MB',
                'GB',
                'TB',
                'PB',
                'EB',
                'ZB',
                'YB'
            ]

            let index = 0
            const sizeValue = parseFloat(value)
            index = Math.floor(Math.log(sizeValue) / Math.log(1024))
            let size = sizeValue / Math.pow(1024, index)
            size = size.toFixed(2)
            return size + units[index]
        },
        $cutSolicitorOriByRules: function (data, rules) {
            //若沒有資料就直接跳出
            if (!data) return '-'
            const includeComma = new RegExp(',')
            let dealContent = data
            let isDataIncludeComma = includeComma.test(dealContent)
            let detectMobile = this.$isMobile
            //rule: normal(不需根據裝置調整＿＿現在是頁面使用) / device (當要根據裝置調整樣式)
            //rule: 不接受傳入空值 ()
            if (rules === 'normal' && isDataIncludeComma) {
                return dealContent.replace(/,/g, '、')
            } else if (rules === 'device' && isDataIncludeComma) {
                if (detectMobile()) return data.replace(/,/g, '、')
                else if (!detectMobile()) return data.replace(/,/g, '<br/>')
            } else {
                return data
            }
        },
        $isSameValue(oldValue, newValue) {
            return _.isEqual(oldValue, newValue)
        },
        $cloneDeepValue(value) {
            return _.cloneDeep(value)
        },
        $inspectPayload: function (payload, method, target) {
            if (method === 'copy') return _.cloneDeep(payload[target])
            if (method === 'isSame')
                return _.isEqual(payload.current, payload.prev)
        },
        $hasPagePermission: function (routeName) {
            /**
             *根據側邊欄Menu有的頁面分類限制使用者使用權限
             *routeName特例:
             發佣作業分事業部制 & 系統制，routeName 需加上 params
             *commission/admin/st -> CommissionAdminSt
            **/

            let hasPermission = false

            const permissions = Object.keys(
                this.$store.state?.permissions
            )?.filter((key) => {
                return key.includes(routeName) && _.startsWith(key, routeName)
            })

            if (permissions?.length > 0) hasPermission = true

            return hasPermission
        },
        $hasPermission: function (action = '') {
            /**
             *內勤權限限制_API功能限制
             *權限項目請對照"bms權限管理共同文件"google試算表
             **/
            //發佣作業僅Admin有權限使用
            const isCommissionAdmin = this.$store.state.permissionRouteName.includes(
                'CommissionAdmin'
            )
            //客戶服務共用的API權限
            const hasTiaPermission = this.$store.state.permissionRouteName?.includes(
                'Tia'
            )
                ? this.$store.state?.permissions['Tia']?.includes(action)
                : false

            // 全公司報表權限
            const hasBackstageReportPermission = this.$store.state?.permissions[
                _.startCase(_.camelCase(this.$route.fullPath)).replace(
                    /\s/g,
                    ''
                )
            ]?.includes(action)
            // Dashboard權限
            const hasDashboardPermission = this.$store.state.permissionRouteName?.includes(
                'Home'
            )
                ? this.$store.state?.permissions['Dashboard']?.includes(action)
                : false

            return !!(
                hasTiaPermission ||
                hasBackstageReportPermission ||
                isCommissionAdmin ||
                hasDashboardPermission ||
                this.$store.state?.permissions[
                    this.$store.state.permissionRouteName
                ]?.includes(action)
            )
        },
        //避免新頁面上線時未更新permission造成無法進任何頁面↓ 新增人力查詢2/15
        $reLoginGetPermissions: function () {
            const today = this.$formatDate(this.$getDate())
            const deadline = '2023-02-20'
            if (today > deadline) return //登出更新人力查詢permission期限為五天
            if (
                !this.$isAdmin() &&
                this.$isLogin() &&
                this.$store?.state?.userInfo.permissions &&
                !this.$hasPagePermission('Organization')
            ) {
                const _this = this
                this.$showErrorMessage(
                    '因應系統更新，請重新登入帳號',
                    '系統將於20秒後回到登入畫面，或點擊「我知道了」重新登入。若持續出現此訊息，請聯繫資訊客服。',
                    function () {
                        _this.$logout()
                    }
                )
                setTimeout(() => {
                    this.$logout()
                }, 20000)
            } else return
        },
        //避免新頁面上線時未更新permission造成無法進任何頁面↑新增人力查詢2/15

        /**
         *帶入一般或是快速查詢條件跳轉到其他頁面上搜尋Table資料
         **/

        $crossPageFilter: function (
            routeName = '',
            normalFilter = {}, //一般搜尋條件，ex:{ manCode: 1234567 } ,key為下拉選項key，value為下拉裡面選項的值
            advancedFilter = { key: '', value: '' }, //快速搜尋條件，key為欄位參數，value為要搜尋的值
            isBlank = false //是否另外開啟分頁
        ) {
            if (!routeName) return

            if (Object.keys(normalFilter)?.length > 0)
                this.$store.commit('set', { normalFilter: normalFilter })

            if (advancedFilter.key) {
                this.$store.commit('set', { advancedFilter: advancedFilter })
            }

            if (isBlank) {
                let routeUrl = this.$router.resolve({
                    name: routeName
                })
                window.open(routeUrl.href, '_blank')
            } else
                this.$router.push({
                    name: routeName
                })
        },
        /**
         *將跨頁帶入的一般搜尋條件 套用至filterPayload
         **/
        $getStorePayload: function (defaultPayload = {}) {
            const filter = this.$store.state.normalFilter

            if (Object.keys(filter).length > 0) {
                const payload = _.cloneDeep(defaultPayload)
                Object.keys(filter).forEach((key) => {
                    payload[key] = filter[key]
                    if (key === 'manCode') {
                        payload.manCode = payload.manCode + ''
                    }
                    if (key === 'manCode' && !filter.titleType) {
                        const account = this.$store.state.accounts.find(
                            (data) => data.key == filter.manCode
                        )
                        payload.titleType = account.titleTypeId
                    }
                })
                this.$store.commit('set', { normalFilter: {} })
                return payload
            } else return defaultPayload
        },
        $shouldUpdateHomeApi: function () {
            const hour = 1000 * 60 * 60 // 1小時的毫秒數
            const now = new Date()
            const lastTime = this.$store.state.lastUpdateHomeApiTime
                ? this.$store.state.lastUpdateHomeApiTime
                : ''

            let diff = now - lastTime

            const diffHours = Math.floor(diff / hour)
            return lastTime ? diffHours > 1 : false
        },
        $shouldUpdateHeaderApi: function () {
            const hour = 1000 * 60 * 60 // 1小時的毫秒數
            const now = new Date()
            const lastTime = this.$store.state.lastUpdateHeaderApiTime
                ? this.$store.state.lastUpdateHeaderApiTime
                : ''

            let diff = now - lastTime
            const diffHours = Math.floor(diff / hour)

            return lastTime ? diffHours > 1 : false
        },
        $setCookie: function (name, value) {
            const data = JSON.stringify(value)
            document.cookie = name + '=' + (data || '') + '; path=/'
        },
        $getCookie: function (name) {
            const nameEQ = name + '='
            const cookies = document.cookie.split(';')
            for (var i = 0; i < cookies.length; i++) {
                let cookie = cookies[i]
                while (cookie.charAt(0) == ' ')
                    cookie = cookie.substring(1, cookie.length)
                if (cookie.indexOf(nameEQ) == 0)
                    return cookie.substring(nameEQ.length, cookie.length)
            }
            return null
        },
        $deleteCookie: function (name) {
            document.cookie =
                name + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
        },
        $isSafari: function () {
            return (
                navigator.userAgent.includes('Safari') &&
                !navigator.userAgent.includes('Chrome') &&
                !navigator.userAgent.includes('Edg')
            )
        },
        $hasCrossFilter: function () {
            const normalFilter = this.$store.state.normalFilter
            const advancedFilter = this.$store.state.advancedFilter
            const safariCookie = this.$getCookie('normalFilter')

            return (
                Object.keys(normalFilter).length > 0 ||
                !!advancedFilter?.key ||
                safariCookie
            )
        },
        $setIframe: function () {
            const iframe = document.createElement('iframe')
            // 設置 iframe 的屬性
            iframe.src = process.env.VUE_APP_BACK_STAGE_URI + `/storage/`
            iframe.id = 'backstage-id'
            iframe.width = '100px'
            iframe.height = '100px'
            iframe.style.visibility = 'hidden'
            // 將 iframe 插入到頁面中指定的父元素中
            const parent = document.querySelector('body')
            parent.appendChild(iframe)
            iframe.addEventListener('load', function (event) {
                if (event.target.contentWindow.window.length === 0) {
                    return false
                } else {
                    return true
                }
            })
        }
        //賓果卡點擊動作紀錄//暫停
        // $recordBingo: async function (
        //     data = {
        //         pageName: '',
        //         action: ''
        //     }
        // ) {
        //     if (process.env.NODE_ENV !== 'production') return

        //     const date = new Date()
        //     const day = String(date.getDate()).padStart(2, '0')
        //     const month = String(date.getMonth() + 1).padStart(2, '0')
        //     const year = date.getFullYear()
        //     const hh = String(date.getHours()).padStart(2, '0')
        //     const mm = String(date.getMinutes()).padStart(2, '0')
        //     const ss = String(date.getSeconds()).padStart(2, '0')
        //     const time = `${year}-${month}-${day} ${hh}:${mm}:${ss}`
        //     const manCode = this.$store?.state?.userInfo?.code
        //     const name = this.$store?.state?.userInfo?.name

        //     if (!data.pageName || !data.action || !manCode) return
        //     try {
        //         const payload = {
        //             manCode: manCode,
        //             name: name,
        //             createdAt: time,
        //             pageName: data.pageName,
        //             action: data.action
        //         }
        //         await api().then(({ postUserActionAPI }) =>
        //             postUserActionAPI(payload)
        //         )
        //     } catch (err) {
        //         console.log(err)
        //         this.$showErrorMessage(
        //             `賓果紀錄儲存發生問題，請稍後再試一次`,
        //             '若持續出現此問題，請洽詢飛鴿。'
        //         )
        //     }
        // }
    }
}

export default {
    install(Vue) {
        Vue.mixin(mixin)
    }
}
